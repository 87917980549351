.container {
    grid-area: quantitypicker;

    :global .error-message {
        margin: 16px 0 0 0;
    }
}

.quantity-picker {
    composes: no-print from global;
    display: inline-flex;
    align-items: center;

    button {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: var(--color-swamp);
        border: none;
        padding: 0;
        display: grid;
        place-content: center;
        --icon-color: white;

        &:hover,
        &:active {
            background-color: var(--color-oxford);
        }

        &:disabled {
            background-color: var(--color-oslo-grey);
            border: 1px solid var(--color-oslo-grey);
            color: var(--color-iron);
        }
    }

    input {
        color: var(--color-swamp);
        width: 40px;
        font-size: 16px;
        text-align: center;
        padding: 0;
        border: none;
        background-size: 1em;
        align-self: stretch;

        &.busy {
            color: transparent;
        }
    }
}

.busy {
    composes: spinner-bg from global;
}
