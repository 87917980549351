.wineCountryItem {
    display: flex;
    flex-direction: column;
    background: white;
    width: min(100%, 950px);
    justify-self: center;
    --wineCountryItem-padding: 16px;
    padding: var(--wineCountryItem-padding);
    gap: 10px;

    li:has(>&) {
        grid-column: 1 / -1;
    }

    @media (--desktop) {
        --wineCountryItem-padding: 40px;
        /* This is a hack to reduce the gap between the definition entries */
        margin-bottom: -24px;
        flex-direction: row;
        gap: 0;

    }

    details {
        scroll-margin-top: inherit;

        @media (--desktop) {
            padding-inline: 40px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            & .district,
            .subdistrict {
                margin-block: 8px;

                a {
                    display: block;
                    border: solid 1px;
                    border-radius: 4px;
                    padding: 8px 16px;
                    color: var(--color-swamp);
                    font-size: var(--font-size-smaller);

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            & .district a {
                background-color: var(--color-old-lace);
                border-color: var(--color-cream-brulee);
                font-weight: bold;
                font-size: var(--font-size-smaller);
            }

            & .subdistrict a {
                background-color: white;
                border-color: var(--color-iron);
                margin-inline: var(--wineCountryItem-padding);
                font-weight: normal;
                font-size: var(--font-size-small);

            }
        }

        summary {
            display: flex;
            list-style: none;
            position: relative;

            & .info {
                display: flex;
                flex-direction: column;
                gap: 10px;

                a {
                    margin-block: 20px;
                    display: block;
                    padding: 16px 24px;
                    max-width: 250px;
                    text-align: center;
                    color: var(--color-oxford);
                    border: 1px solid var(--color-iron);
                    border-radius: 4px;

                    span {
                        display: inline-block;
                        text-decoration: inherit;

                        &::first-letter {
                            text-transform: lowercase;
                        }
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &::marker,
            &::-webkit-details-marker {
                /* hack for safari */
                display: none;
            }

            h2 {
                font-family: var(--font-family-serif);
                font-weight: bold;
                font-size: var(--font-size-h3);
                margin-bottom: 8px;
            }

            p:last-of-type {
                margin-bottom: 0;
            }
        }

        &:has(>ul>li) {
            summary {
                cursor: pointer;

                h2 {
                    padding-right: 40px;
                    font-family: var(--font-family-serif);
                    padding-bottom: 0;
                }

                &::after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                    background: var(--color-grey-02) url(../../../images/svg/icon-chevron-down.svg) no-repeat center;
                    background-size: 14px;
                    right: 16px;
                    top: -16px;
                }

                &:hover::after {
                    background-color: var(--color-grey-03);
                }

                @media (--desktop) {
                    padding-right: calc(var(--wineCountryItem-padding) * 3);

                    h2 {
                        padding-right: 0;
                    }

                    &::after {
                        top: calc(50% - 40px);
                    }
                }
            }

            &[open] summary {
                &::after {
                    background-image: url(../../../images/svg/icon-chevron-up.svg);
                }
            }
        }
    }
}