.opening-hours {
  h3 {
    font-size: var(--font-size-smaller);
    font-family: var(--font-family-sans-serif);
    font-weight: bold;
    text-align: left;
    margin-bottom: 8px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-column: 1 / -1;
    gap: 8px 16px;

    li {
      padding: 0;
      display: grid;
      grid-template-columns: subgrid;
      grid-column: 1 / -1;
      align-items: center;

      span {
        text-wrap: balance;
      }

      span:last-child {
        text-align: end;
      }
    }
  }
}