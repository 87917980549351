.container {
  width: stretch;
  max-width: var(--max-content-width);
  margin-inline: auto;
  background: var(--color-mint-green);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px 16px;

  @media (--desktop) {
    gap: 32px;
    padding: 40px;

  }

  img {
    width: 80px;
  }

  h2 {
    font-family: var(--font-family-serif);
    text-align: center;
  }

  p {
    margin-bottom: 8px;
    color: inherit;
  }

  .opening-hours {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (--desktop) {
      gap: 24px;
    }
  }

  :global .btn {
    text-align: center;
    line-height: 1.4;
    text-wrap: balance;
  }
}