.facet-button {
    font-weight: bold;
    font-size: 13px;
    line-height: 110%;
    padding: 12px;
    display: inline-flex;
    align-items: center;
    column-gap: 8px;
    border: 1px solid var(--color-iron);
    border-radius: var(--border-radius);
    background: var(--color-white);

    &:hover {
        background-color: var(--color-grey-02);
    }

    &.selected {
        background: var(--color-deep-sea-green);
        color: var(--color-white);
        border: none;

        &:hover {
            text-decoration: underline;
            background-color: var(--color-blue-stone);
        }
    }

    &.selectable {
        background: var(--color-mint-green);
        border: none;

        &:hover {
            text-decoration: underline;
            background-color: var(--color-chinook);
        }
    }
}