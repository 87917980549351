.facet-value {
  --icon-size: 20px;
  background-color: var(--color-white);
  color: var(--secondary-text-color);
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 16px;
  border: none;
  line-height: 20px;

  &:hover,
  &:active {
    background-color: var(--color-grey-03);
    border: none;
    text-decoration: none;

    .name {
      text-decoration: underline;
    }
  }

  /*
  Make sure the single facets at the bottom of the search page facet list
  have rounded corners and black text
  */
  :global(.facet-group__singleValues) & {
    border-radius: 4px;
    color: unset;
  }

  :global .product-icon {
    /* prevent the icon from squashing on small screens */
    flex-shrink: 0;
  }
}

/*
This is for the FacetValues used in product-guide
They should probably be separate components...
*/
:global(.product-guide) {

  :global(.facet__list--grid) .facet-value {
    --icon-size: 40px;
    flex-direction: column;
    justify-content: center;
    border: 1px solid var(--color-iron);
    border-radius: 4px;

    .name {
      flex: unset;
      text-align: center;
    }
  }

  :global(.facet__list--centered) .facet-value {
    border: 1px solid var(--color-iron);
    border-radius: 4px;
    justify-content: center;

    .name {
      flex: unset;
      text-align: center;
    }
  }

  :global(.facet__list--styleCategory) .facet-value {
    --style-size: 105px;

    @media (--to-small) {
      --style-size: 86px;
    }

    border: 1px solid var(--color-iron);
    border-radius: 4px;
    display: grid;
    grid-template: "icon title" auto
    "icon description" 1fr
    / var(--style-size) 1fr;
    grid-gap: 8px 16px;
    align-items: start;
    padding: 16px;
    text-align: left;

    :global .product-icon {
      background-size: cover;
      grid-area: icon;
      --icon-size: var(--style-size);
    }
  }
}

.name {
  font-size: 14px;
  font-weight: bold;
  flex: 1 1 auto;
  grid-area: title;
}

.count {
  color: var(--color-nevada);
  font-weight: normal;
  font-size: 12px;
}

.description {
  margin: 0;
  grid-area: description;
}