.tag-list {
    --gap: 4px;
    width: 100%;
    padding: 0;
    margin-top: var(--margin-top);
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap);
    list-style: none;
    margin-bottom: 0;

    li {
        position: relative;
    }

    button {
        background-color: var(--tag-color, var(--color-grey-02));
        padding: 8px;
        border: 0;
        min-width: initial;
        display: inline-block;
        font-size: 10px;
        font-weight: bold;
        color: var(--color-swamp);
        letter-spacing: 1px;
        text-transform: uppercase;
        white-space: nowrap;

        &:hover {
            opacity: 0.8;
        }
    }
}

.popup {
    width: min(300px, 100vw - calc(2 * var(--product__layout-padding, 0)));
    font-weight: normal;
    padding: 16px;
    position: absolute;
    z-index: 1;
    background-color: var(--tag-color, --color-grey-02);
    color: var(--color-swamp);
    border-radius: 8px;
    top: calc(100% + var(--gap));

    .title {
        display: flex;
        margin-bottom: 24px;
        justify-content: space-between;
    }

    h3 {
        font-family: var(--font-family-serif-ingress);
        text-align: left;
        margin: 0;
    }

    p {
        color: inherit;
        margin-bottom: 0;
    }
}
