ul.facets {
    --facets-horizontal-margin: 16px;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    > li {
        /* prevent margin collapsing at bottom of .facet-values--overflow-scroll */
        display: grid;

        :global(.expandable > button) {
            padding: 16px;

            &:hover {
                background: var(--color-grey-03);
            }
        }
    }
}

ul.facet-group {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0;
    padding: 0;

    :global(.expandable) > & {
        padding: 0 16px;
        margin-bottom: 16px;
        display: block;
    }

    .facet-name {
        font-size: 12px;
        text-transform: uppercase;

        + ul.facet-values {
            margin: 16px 0;
        }
    }
}

:global(li.facet-group__singleValues) {
    margin-top: var(--facets-horizontal-margin);
}

ul.facet-values {
    list-style: none;
    margin: 16px var(--facets-horizontal-margin, 16px);
    margin-top: 0;
    padding: 0;
    display: block;

    ul.facet-group & {
        margin: 0;
    }

    ul.facets &:global(.facet-values--overflow-scroll) {
        overflow-y: auto;
        max-height: 225px;

        @supports (animation-timeline: scroll()) {
            scroll-timeline: --scroll-timeline y;
            animation: detect-scroll;
            animation-timeline: --scroll-timeline;
            animation-fill-mode: none;

            &::before,
            &::after {
                content: '';
                display: block;
                position: sticky;
                left: 0;
                right: 0;
                height: 20px;

                animation-name: reveal;
                animation-timeline: --scroll-timeline;
                animation-fill-mode: both;
                visibility: var(--can-scroll, hidden);
                pointer-events: none;
            }

            &::before {
                top: 0;
                animation-range: 20px 40px;
                background: linear-gradient(to bottom, #3331, #3330);
                margin-bottom: -20px;
            }

            &::after {
                bottom: 0;
                animation-direction: reverse;
                animation-range: calc(100% - 40px) calc(100% - 20px);
                background: linear-gradient(to top, #3331, #3330);
                margin-top: -20px;
            }

            /* Only show Scroll Indicators when the scroller can actually scroll */
            @keyframes detect-scroll {
                from,
                to {
                    --can-scroll: visible;
                }
            }

            /* Scroll Indicators */
            @keyframes reveal {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }
        }
    }

    > li {
        border-bottom: 1px solid var(--color-grey-03);

        &:first-of-type {
            border-top: 1px solid var(--color-grey-03);
        }
    }
}

:global(.expandable) {
    .boolean-facet {
        border-bottom: 1px solid var(--color-grey-03);

        &:nth-child(-n + 1 of .boolean-facet) {
            border-top: 1px solid var(--color-grey-03);
        }
    }
}
