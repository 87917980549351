.info {
    font-weight: bold;
    font-size: 16px;
    color: var(--color-oxford);

    @media (--to-medium) {
        font-size: 14px;
    }
}

.store-stock-locator {
    :global(.product-mini-card) {
        padding: 32px;

        @media (--from-medium) {
            img {
                align-self: center;
                max-width: 300px;
                max-height: 300px;
            }
        }

        @media (--from-xlarge) {
            padding: 40px;
        }
    }

    @media (--from-medium) {
        &:global(.dialog-split) {
            grid-template-columns: 300px 1fr;
        }
    }
}