.drink {
  scroll-margin-top: var(--header-height, 0);
  --drink-img-size: 100px;
  --drink-padding-right: 16px;
  --drink-padding-block: 16px;
  --drink-column-gap: 16px;
  margin-block: 10px;

  @media (--desktop) {
    margin-block: -12px;
    --drink-img-size: 165px;
    --drink-padding-right: 40px;
    --drink-padding-block: 24px;
    --drink-column-gap: 40px;
  }

  ul:has(> li > &) {
    grid-template-columns: 1fr;
  }

  details {
    background: white;
    position: relative;

    padding-bottom: calc(40px / 2 + 16px);

    @media (--desktop) {
      padding-bottom: 0;
    }

    summary {
      display: grid;

      grid-template:
        'image' auto 'tags' auto 'title' auto
        / 1fr;

      gap: 16px var(--drink-column-gap);
      scroll-margin-top: inherit;
      list-style: none;

      @media (--desktop) {
        padding: var(--drink-padding-block) calc(2*var(--drink-padding-right) + 40px) var(--drink-padding-block) 0;
        grid-template:
          'image title' auto
          'image tags' auto
          / var(--drink-img-size) 1fr;
        place-items: start;

        /* Make sure the ::after icon is positioned only inside the summary on desktop */
        position: relative;
      }

      &::marker,
      &::-webkit-details-marker {
        /* hack for safari */
        display: none;
      }

      ul {
        grid-area: tags;
      }

      picture {
        grid-area: image;

        @media (--desktop) {
          margin-block: calc(0px - var(--drink-padding-block));
        }

        img {
          width: 100%;
        }
      }

      h2 {
        grid-area: title;
        align-self: end;
        font-family: var(--font-family-serif);
        margin-bottom: 0;

        @media (--mobile) {
          text-align: center;
        }
      }


      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background: var(--color-grey-02) url(../../../images/svg/icon-chevron-down.svg) no-repeat center;
        background-size: 14px;
        right: calc(50% - 40px / 2);
        bottom: calc(-20px);
        transition: transform 600ms;

        @media (--desktop) {
          top: calc(50% - 40px / 2);
          right: var(--drink-padding-right);
        }

      }
    }


    &[open] summary {
      &::after {
        transform: rotate(180deg);
      }
    }

    @media (prefers-reduced-motion: no-preference) {
      &::details-content {
        interpolate-size: allow-keywords;
        block-size: 0;
        overflow-y: clip;
        transition:
          content-visibility 600ms allow-discrete,
          block-size 600ms;
      }

      &[open]::details-content {
        block-size: auto;
      }
    }
  }
}

.info {
  margin: 0 var(--drink-padding-block);

  @media (--desktop) {
    margin-left: calc(var(--drink-img-size) + var(--drink-column-gap));
    padding-bottom: var(--drink-padding-block);
  }

  ul,
  p {
    color: var(--color-paragraph);
    font-size: var(--font-size-p);
    line-height: 1.5;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
  }
}