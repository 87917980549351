.alphabet {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;

    a,
    span {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        background-color: var(--color-white);
        border-radius: 4px;
        padding: 4px;
        color: var(--color-swamp);
        border: 1px solid transparent;
    }

    a {
        color: var(--color-oxford);
    }

    a:hover {
        text-decoration: underline;
        background-color: var(--color-grey-02);
        border-color: var(--color-iron);
    }

    span {
        color: var(--color-nevada);
        background-color: transparent;
    }
}