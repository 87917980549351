.teaser {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 24px;
    justify-content: center;

    @media (--desktop) {
        gap: 32px;
    }

    >h2 {
        font-family: var(--font-family-serif);
        font-size: var(--font-size-h2);
    }

    >p {
        font-family: var(--font-family-serif-ingress);
        font-size: var(--font-size-h4);
        line-height: 1.5;
        text-wrap: balance;
    }

    >h2,
    >p {
        text-align: center;
        margin: 0 auto;
        max-width: var(--max-content-width);
    }

    >ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
        align-content: stretch;
        gap: 24px;

        >li {
            display: grid;
        }

        @media (--desktop) {
            column-gap: 40px;


            .triple& {
                grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);

                >li:first-of-type {
                    grid-row: span 2;
                }
            }

            >li:only-of-type a {
                grid-template: 'info' / minmax(0, 1fr);
                align-items: center;

                &:has(picture) {
                    grid-template: 'image info' / minmax(0, 1fr) minmax(0, 1fr);

                    .single-right& {
                        grid-template-areas: 'info image';
                    }
                }
            }
        }

        &.green,
        &.beige {
            >li a {
                background-color: var(--teaser-bg);
            }
        }
    }

    &.green,
    &.beige {
        background-color: var(--teaser-bg);
        position: relative;
        padding-block: 40px;

        @media (--desktop) {
            padding-block: 80px;
        }

        /*
        Make the green background wider than this box
        This trick uses box-shadow to make the green width 3x as wide as this box
        Hopefully nobody on a very wide screen looks at this page (if they do and they complain then the solution is to add more box-shadows at 2*100cqw, 3*100cqw, etc
        box-shadow does not accept 100%, so I have to use 100cqw to move it left and right the width of the box
        */
        @container sanity-article (min-width: 1px) {
            box-shadow: calc(0px - 100cqw) 0 var(--teaser-bg), 100cqw 0 var(--teaser-bg);
        }
    }
}

.green {
    --teaser-bg: var(--color-mint-green);
}

.beige {
    --teaser-bg: var(--color-old-lace);
}