/* This is needed for specificity */
:global(article.sanity) {
  .article-info {
    color: var(--color-nevada);
    font-size: var(--font-size-small);
    font-family: var(--font-family-sans-serif);
    display: flex;
    flex-direction: column;

    .no-break {
      width: max-content;
    }

    &:empty {
      display: none;
    }

    span {
      display: block;
    }
  }
}