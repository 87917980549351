a.brochure {
    display: grid;
    background-color: var(--color-mint-green);
    border-bottom: none;
    padding: 16px;
    gap: 16px;
    color: var(--color-swamp);
    grid-template-columns: 90px 1fr;
    align-items: center;

    img {
        width: 90px;
    }

    .info {
        display: grid;
        gap: 8px;

        h2,
        span {
            font-size: 14px;
        }

        h2 {
            font-family: var(--font-family-serif);
            color: var(--color-primary);
            text-align: center;
            line-height: 20px;
            word-wrap: break-word;
        }

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            color: var(--color-oxford);
        }
    }

    @media (--desktop) {
        padding: 24px;
        grid-template-columns: unset;
        align-items: unset;

        img {
            width: unset;
        }

        .info {

            h2,
            span {
                font-size: 16px;
            }

            h2 {
                line-height: 22px;
            }
        }
    }

    &:hover {
        h2 {
            text-decoration: underline;
        }
    }
}