.add-to-cart {
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    text-align: center;
    color: var(--color-white);
    background-color: var(--color-swamp);
    border-radius: 20px;
    padding: 13px 32px;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 120px;
    border: none;

    &:hover {
        background-color: var(--color-oxford);
    }
}

.not-buyable {
    height: 40px;
}