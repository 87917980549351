ul.tags {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  li {
    display: block;

    a {
      background: white;
      border-radius: 4px;
      padding: 4px 16px;
      color: var(--color-oxford);
      font-size: var(--font-size-small);
      line-height: 1.4;
      border-bottom: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}